/* eslint-disable no-unused-vars */
import React, {useState} from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from '../CommonElements/condAlert.js'
import './MainFrame.css'
import { useTranslation } from "react-i18next";
import axios from 'axios'
import Button from 'react-bootstrap/esm/Button';
function Contact() {
  const { t } = useTranslation();
  function onNameChange(event) {
    setName(event.target.value)
  }

  function onEmailChange(event) {
    setEmail(event.target.value)
    
  }

  function onMessageChange(event) {
    setMessage(event.target.value)
  }

  function handleSubmit(event) {
    setShow(2);
    event.preventDefault();
    if(email === "oficina@normacar.com") 
    {
      setEmail('');
      return;
    }
    var aux = 
    {
      name: name,
      email: email,
      message: message
    }
    axios({
      method: "POST", 
      url:"https://normacar.com/send", 
      data:  aux
    }).then((response)=>{
      if (response.data.status === 'success') {
        setAlText(t("CN_successMsg"));
        setAlType('success')
        setShow(1);
        resetForm()
      } else /*if(response.data.status === 'fail')*/ {
        
        setAlText(t("CN_failureMsg"));
        setAlType('warning')
        setShow(1);
      }
    }).catch
    {
      setAlText(t("CN_failureMsg"));
      setAlType('warning')
      setShow(2);
    }
  }
  function resetForm(){
    setName('')
    setEmail('')
    setMessage('')
  }
  function onClose()
    {
        setShow(0);
    }
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [show, setShow] = useState(0);
    const [alText, setAlText] = useState('');
    const [alType, setAlType] = useState('');
    return (
            <Container fluid = "md" >
                <Row>
                  <Col><h1 className="h1">{t("CN_title")}</h1></Col>
                </Row>
                <Row >
                  <Col>{t("CN_mov")}627 95 10 55</Col>
                </Row>
                <Row className = "lastRowSection">
                <Col><Button type="button" className="btn btn-primary" href="tel:+34627951055">{t("CN_callus")}</Button></Col>
                </Row>
                <Row>
                  <Col>{t("CN_tel")}938 21 44 73</Col>
                </Row>
                <Row className = "lastRowSection">
                <Col><Button type="button" className="btn btn-primary" href="tel:+34938214473">{t("CN_callus")}</Button></Col>
                </Row>
                <Row>
                  <Col>{t("CN_email")}oficina@normacar.com</Col>
                </Row>
                <Row className = "lastRowSection">
                <Col><Button type="button" className="btn btn-primary" href="mailto:oficina@normacar.com">{t("CN_mailus")}</Button></Col>
                </Row>
                
                <Row>
                  <Col>{t("CN_form0")}</Col>
                </Row>
                
                <Row className = "lastRowSection">
                  <Col>
                   <form id="contact-form" onSubmit={handleSubmit.bind(this)} method="POST">
                    <div className="form-group">
                      <label htmlFor="name">{t("CN_formName")}</label>
                      <input type="text" className="form-control" value={name} onChange={onNameChange.bind(this)}/>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">{t("CN_formEmail")}</label>
                      <input type="email" className="form-control" aria-describedby="emailHelp" value={email} onChange={onEmailChange.bind(this)}/>
                    </div>
                    <div className="form-group">
                      <label htmlFor="message">{t("CN_formMessage")}</label>
                      <textarea className="form-control" rows="5" value={message} onChange={onMessageChange.bind(this)}></textarea>
                    </div>
                    <Button type="submit" className="btn btn-primary" size="xl">{t("CN_formSubmit")}</Button>
                  </form>
                  </Col>
                </Row>
                <Row className = "lastRowSection">
                  <Col>
                    <Alert text = {alText} show = {show} state={alType} onClose={onClose}/>
                  </Col>
                </Row>
                
            </Container>
    );
  }
  export default Contact;