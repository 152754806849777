import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import ListGroup from 'react-bootstrap/ListGroup'
import './MainFrame.css'
import Carousel from 'react-bootstrap/Carousel'
import classic from '../images/classic.jpg'
import comercial from '../images/comercial.jpg'
import turisme from '../images/turisme.JPG'
import vivenda from '../images/vivenda.jpg'
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import './carrousel.css'
function Body() {
  const history = useHistory();
  const handleClick = () => {
    history.push("/contact");
}
    const { t } = useTranslation();
    return (
          
            <Container fluid = "md" >
                <Row className="lastRowSection">
                  <Col><h1 className="h1">{t("HM_title")}</h1></Col>
                </Row>
                <Row className="lastRowSection">
                  <Col>
                <Carousel>
                  <Carousel.Item interval={1000}>
                    <img
                     className="d-block carr-img"
                      src={classic}
                      alt="First slide"
                    />
                    <Carousel.Caption>
                      <h3>{t("HM_footer4")}</h3>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item interval={500}>
                    <img
                    className="d-block carr-img"
                    src={comercial}
                    alt="Second slide"
                    />
                    <Carousel.Caption>
                      <h3>{t("HM_footer3")}</h3>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                    className="d-block carr-img"
                    src={turisme}
                    alt="Third slide"
                    />
                    <Carousel.Caption>
                      <h3>{t("HM_footer2")}</h3>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                    className="d-block carr-img"
                    src={vivenda}
                    alt="Fourth slide"
                  />
                    <Carousel.Caption>
                      <h3>{t("HM_footer1")}</h3>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
                </Col></Row>
                <Row className="lastRowSection">
                  <Col>{t("HM_consult")}</Col>
                </Row>
                <Row className="lastRowSection">
                  <Col><Button variant="primary" size="xxl" onClick={handleClick}>{t("HM_contact")}</Button></Col>
                </Row>
                <Row className="lastRowSection">
                  <Col><h2 className="h2">{t("HM_info")}</h2></Col>
                </Row>
                <Row className="lastRowSection">
                  <Col>
                    <h3 className="h3">{t("HM_footer1")}</h3>
                    <p>{t("HM_vivtext")}<br/>{t("HM_vivtext2")}<br/></p>
                    <h5 className="h5">{t("HM_vivtext3")}</h5>
                    <ListGroup>
                    <ListGroup.Item className = "BG_whitesmoke"><b>{t("HM_vivtext4v1")}</b>{t("HM_vivtext4v2")}</ListGroup.Item>
                    <ListGroup.Item className = "BG_whitesmoke"><b>{t("HM_vivtext5v1")}</b>{t("HM_vivtext5v2")}</ListGroup.Item>
                    <ListGroup.Item className = "BG_whitesmoke"><b>{t("HM_vivtext6v1")}</b>{t("HM_vivtext6v2")}</ListGroup.Item>
                    <ListGroup.Item className = "BG_whitesmoke"><b>{t("HM_vivtext7v1")}</b>{t("HM_vivtext7v2")}</ListGroup.Item>
                    <ListGroup.Item className = "BG_whitesmoke"><b>{t("HM_vivtext8v1")}</b>{t("HM_vivtext8v2")}</ListGroup.Item>
                    <ListGroup.Item className = "BG_whitesmoke"><b>{t("HM_vivtext9v1")}</b>{t("HM_vivtext9v2")}</ListGroup.Item>
                    <ListGroup.Item className = "BG_whitesmoke"><b>{t("HM_vivtext10v1")}</b>{t("HM_vivtext10v2")}</ListGroup.Item>
                    <ListGroup.Item className = "BG_whitesmoke"><b>{t("HM_vivtext11v1")}</b>{t("HM_vivtext11v2")}</ListGroup.Item>
                    <ListGroup.Item className = "BG_whitesmoke"><b>{t("HM_vivtext12v1")}</b>{t("HM_vivtext12v2")}</ListGroup.Item>
                    <ListGroup.Item className = "BG_whitesmoke"><b>{t("HM_vivtext13v1")}</b>{t("HM_vivtext13v2")}</ListGroup.Item>
                    <ListGroup.Item className = "BG_whitesmoke"><b>{t("HM_vivtext14v1")}</b>{t("HM_vivtext14v2")}</ListGroup.Item>
                    </ListGroup>
                  </Col>
                  </Row>
                <Row className="lastRowSection">
                  <Col>
                    <h3 className="h3">{t("HM_footer4")}</h3>
                    <p>{t("HM_cltext")}<br/>{t("HM_cltext2")}<br/>{t("HM_cltext3")}<br/>{t("HM_cltext4")}<br/>{t("HM_cltext5")}</p>
                  </Col>
                </Row>
                <Row className="lastRowSection">
                  <Col>
                    <h3 className="h3">{t("HM_footer3")}</h3>
                    <p>{t("HM_comertext")}</p>
                  </Col>
                </Row>
                <Row className="lastRowSection">
                  <Col>
                    <h3 className="h3">{t("HM_footer2")}</h3>
                    <p>{t("HM_turtext1")}<br/>{t("HM_turtext2")}</p>
                    <h5 className="h5">{t("HM_vivtext3")}</h5>
                    <ListGroup>
                    <ListGroup.Item className = "BG_whitesmoke"><b>{t("HM_turtext3v1")}</b>{t("HM_turtext3v2")}</ListGroup.Item>
                    <ListGroup.Item className = "BG_whitesmoke"><b>{t("HM_turtext4v1")}</b>{t("HM_turtext4v2")}</ListGroup.Item>
                    <ListGroup.Item className = "BG_whitesmoke"><b>{t("HM_turtext5v1")}</b>{t("HM_turtext5v2")}</ListGroup.Item>
                    <ListGroup.Item className = "BG_whitesmoke"><b>{t("HM_turtext6v1")}</b>{t("HM_turtext6v2")}</ListGroup.Item>
                    </ListGroup>
                  </Col>
                </Row>
             </Container>
    );
  }
  export default Body;