import React from 'react';
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'

function condAlert(props)
{
    
    const text = props.text;
    var cond = props.show;
    const state = props.state;
    const onClose = props.onClose;
    if(cond === 2)
    {
        return(<Spinner animation="border" variant="danger" />);
    }
    if(cond === 1)
    {
        return(
        <Alert variant = {state} onClose = {onClose} dismissible> {text} </Alert>
        )
    }
    return (<b/>);
}

export default condAlert;