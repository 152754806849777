import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './MainFrame.css'
import { useTranslation } from "react-i18next";
function About() {
  const { t } = useTranslation();
    return (
            <Container fluid = "md" >
                <Row>
                  <Col><h1 className="h1">{t("AB_title")}</h1></Col>
                </Row>
                <Row>
                  <Col>
                     {t("AB_sentence1")}
                    <b>{t("AB_sentence2")} </b> {t("AB_sentence3")}
                  </Col>
                </Row>
                <Row>
                  <Col> <b>{t("AB_sentence5")}</b>  </Col>
                </Row>
                <Row className = "lastRowSection">
                  <Col>{t("AB_sentence6")}</Col>
                </Row>
             </Container>
    );
  }
  export default About;