import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import logo1 from './LogoBlanc3.png';
import logo2 from './LogoBlanc4.png';
import { useHistory } from 'react-router-dom';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const languageMap = {
  es: { label: "Español", dir: "ltr", active: true },
  cat: { label: "Català", dir: "ltr", active: false }
};

function nn(){
  //Language
  const selected = localStorage.getItem("i18nextLng") || "es";
  const { t } = useTranslation();

  const [menuAnchor, setMenuAnchor] = React.useState(null);
  React.useEffect(() => {
    document.body.dir = languageMap[selected].dir;
  }, [menuAnchor, selected]);
  //Routing
  const history = useHistory();
  const handleClick = (event) => {
      let arg1 = event.target.getAttribute('path');
      // We can add more arguments as needed...
      history.push(arg1);
      // Hope the function is in the window.
      // Else the respective object need to be used
      };
return (
  <Navbar expand="lg" variant = "dark" className="App-header">
  <Navbar.Brand onClick = {handleClick} path = "/">
    <img src={logo1} className="App-logo" alt="logo" />
    <img src={logo2} className="App-logo2" alt="logo" />
    </Navbar.Brand>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav" >
    <Nav>
      <Nav.Link onClick = {handleClick} path = "/">{t("NB_Home")}</Nav.Link>
      <Nav.Link onClick = {handleClick} path = "/about">{t("NB_About")}</Nav.Link>
      <Nav.Link onClick = {handleClick} path = "/contact">{t("NB_Contact")}</Nav.Link>
    </Nav>
    <Navbar.Collapse className="Justify-content-end">
     <NavDropdown as={ButtonGroup} title=
     {
        <span className="App-header">{t("language")}</span>
     }
      id="nav-dropdown">
        <NavDropdown.Item onClick={() => {
                  i18next.changeLanguage("cat");
                  setMenuAnchor(null);
                }}>Català</NavDropdown.Item>
        <NavDropdown.Item onClick={() => {
                  i18next.changeLanguage("es");
                  setMenuAnchor(null);
                }}>Español</NavDropdown.Item>
      </NavDropdown>
    </Navbar.Collapse>
  </Navbar.Collapse>
</Navbar>);

}
export default nn;