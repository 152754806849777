/* eslint-disable no-unused-vars */
import React from 'react'
import { useHistory } from 'react-router-dom';
import {Container, Row, Col} from 'react-bootstrap'
 
function nn(){
   //Routing
   const history = useHistory();
   const handleClick = (event) => {
       let arg1 = event.target.getAttribute('path');
       // We can add more arguments as needed...
       history.push(arg1);
       // Hope the function is in the window.
       // Else the respective object need to be used
       };
return (
  <div expand="lg" variant = "dark" className="App-footer">
    <Container className="footerPad">
      <Row>
      <Col><a onClick={handleClick} path = "/privacy"> Politica de privacidad</a></Col>
        <Col> &copy;2021  Oficina Tècnica Freixa S.L.    </Col>
        <Col><a onClick={handleClick} path = "/legal"> Aviso legal</a></Col>
    </Row>
  </Container>
</div>);
}
export default nn;