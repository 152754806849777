import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationES from "./locales/es/translation.json";
import translationCAT from "./locales/cat/translation.json";

const fallbackLng = ['es'];
const availableLanguages = ['es', 'cat'];
const resources = {
    es: {
      translation: translationES
    },
    cat: {
      translation: translationCAT
    }
  };
i18n
  .use(Backend) // load translations using http (default public/assets/locals/en/translations)
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    resources,
    fallbackLng,

    detection: {
      checkWhitelist: true
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
  });

export default i18n;