/* eslint-disable no-unused-vars */
import React from 'react';
import './App.css';
import BodyAlt from './Routes/HomeAlt'
import Contact from './Routes/Contact.js';
import About from './Routes/About.js'
import Legal from './Routes/legal.js'
import Privacy from './Routes/privacy.js'
import NewNavBar from './nNavBar'
import Footer from './footer'
import {  Route, BrowserRouter } from 'react-router-dom'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <div className="wrapper">
      <NewNavBar/>
      <div className = "mx-md-5 Main-Part">
        <Route exact path = '/' component= {BodyAlt}></Route>
        <Route path = '/contact' component= {Contact}></Route>
        <Route path = '/about' component= {About}></Route>
        <Route path = '/legal' component= {Legal}></Route>
        <Route path = '/privacy' component= {Privacy}></Route>
      </div>
      </div>
      <Footer/>
      </BrowserRouter>
    </div>
  );
}
/**/ 
export default App;
